<template>
  <div
    v-if="image"
    class="image-card"
    @click="edit = !edit"
    @mouseover="edit = !edit"
  >
    <template v-if="portrait_size">
      <b-img
        :src="image.image"
        fluid
        alt="Imagen"
        class="portrait-img"
        @click="zoomImg"
      ></b-img>
    </template>
    <template v-else>
      <b-img
        :src="image.image"
        fluid
        alt="Imagen"
        @click="zoomImg"
        v-b-tooltip.v-secondary.noninteractive="
          `${zoom_image ? 'Click para expandir imagen' : ''}`
        "
      ></b-img>
    </template>
    <!-- <template v-if="portrait_size">
      <b-img
        :src="image.image"
        fluid
        alt="Imagen"
        @click="zoomImg"
        @mouseover="showOptions"
        @mouseleave="hideOptions"
      ></b-img>
    </template>
    <template v-else>
      <b-img
        :src="image.image"
        fluid
        alt="Imagen"
        @click="zoomImg"
        @mouseover="showOptions"
        @mouseleave="hideOptions"
        v-b-tooltip.v-secondary.noninteractive="'Click para expandir imagen'"
      ></b-img>
    </template> -->
    <div
      v-if="allows_crud"
      class="button-div"
      :class="{ 'portrait-button-adjust': portrait_size }"
      v-b-tooltip.v-secondary.noninteractive="'Ver opciones de imagen'"
    >
      <b-dropdown
        class="rounded-button my-0 py-0"
        size="sm"
        variant="link"
        no-caret
        dropright
      >
        <template v-slot:button-content>
          <div class="img-display-options">
            <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
            <!-- <b-icon-card-image scale="1.5"></b-icon-card-image> -->
            <!-- <b-icon-pencil-fill
              class="custom-pencil-icon"
            ></b-icon-pencil-fill> -->
          </div>
        </template>
        <template v-if="display_options">
          <b-dropdown-item class="my-0 py-0" size="sm" @click="alignLeft">
            <b-icon-justify-left class="mr-2"></b-icon-justify-left>Alinear a la
            izquierda
          </b-dropdown-item>
          <b-dropdown-item class="my-0 py-0" size="sm" @click="alignCenter">
            <b-icon-justify class="mr-2"></b-icon-justify>Centrar
          </b-dropdown-item>
          <b-dropdown-item class="my-0 py-0" size="sm" @click="alignRight">
            <b-icon-justify-right class="mr-2"></b-icon-justify-right>Alinear a
            la derecha
          </b-dropdown-item>
          <b-dropdown-item class="my-0 py-0" size="sm" @click="alignTop">
            <b-icon-arrow-bar-up class="mr-2"></b-icon-arrow-bar-up>Alinear
            arriba
          </b-dropdown-item>
          <b-dropdown-item class="my-0 py-0" size="sm" @click="alignBottom">
            <b-icon-arrow-bar-down class="mr-2"></b-icon-arrow-bar-down>Alinear
            abajo
          </b-dropdown-item>
          <!-- <b-dropdown-item class="my-0 py-0" size="sm" @click="zoomImg">
          <b-icon-zoom-in class="mr-2"></b-icon-zoom-in>Zoom
        </b-dropdown-item> -->
          <b-dropdown-divider></b-dropdown-divider>
        </template>
        <b-dropdown-item
          v-if="updated_botton"
          @click="showEditImage"
          class="my-0 py-0"
          size="sm"
        >
          <b-icon-pencil-square class="mr-2"></b-icon-pencil-square>Cambiar
          imagen
        </b-dropdown-item>
        <b-dropdown-item
          v-if="deleted_botton"
          @click="deleteImage"
          class="my-0 py-0"
          size="sm"
        >
          <b-icon-trash class="mr-2"></b-icon-trash>Eliminar imagen
        </b-dropdown-item>
      </b-dropdown>
      <!-- <button v-b-tooltip.bottom.noninteractive class="rounded-button" title="Opciones">
        <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
      </button>-->
      <b-modal
        title="Cambiar imagen"
        :id="`modal-edit-img-${image_id}`"
        size="md"
        hide-footer
        @hide="() => (tem_image = null)"
      >
        <b-form-file
          class="mt-3 mb-1"
          v-model="tem_image"
          placeholder="Seleccione o arrastre la imagen aquí..."
          drop-placeholder="Arrastre la imagen aquí..."
          accept="image/*"
        ></b-form-file>
        <template v-if="tem_image != null && validateImgFile()">
          <b-button @click="updatedImage" class="mt-2 float-right"
            >Guardar</b-button
          >
        </template>
        <template v-else>
          <b-button :disabled="true" class="mt-2 float-right">Guardar</b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "ImageEditor",
  props: {
    image_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    display_options: {
      type: Boolean,
      default: true,
    },
    portrait_size: {
      type: Boolean,
      default: false,
    },
    zoom_image: {
      type: Boolean,
      default: true,
    },
    deleted_botton: {
      type: Boolean,
      default: true,
    },
    updated_botton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image_form: {
        height: 50,
        width: 50,
        horizontal_align: 2,
        vertical_align: 1,
      },
      tem_image: null,
      edit: false,
      // mouse_over: false,
    };
  },
  computed: {
    ...mapGetters({
      images: names.IMAGES,
    }),
    image() {
      return this.images.find((x) => x.id == this.image_id);
    },
  },
  methods: {
    deleteImage() {
      this.$store.dispatch(names.DELETE_IMAGE, this.image_id).then(() => {
        toast("Imagen eliminada.");
        this.$emit("deleted", this.image_id);
      });
    },
    showEditImage() {
      this.$bvModal.show(`modal-edit-img-${this.image_id}`);
    },
    validateImgFile() {
      if (this.tem_image) {
        const allowedExtensions = ["jpg", "jpeg", "png", "gif"]; // Extensiones permitidas
        const fileExtension = this.tem_image.name
          .split(".")
          .pop()
          .toLowerCase(); // Obtener la extensión del archivo
        // Validar si la extensión del archivo está en la lista de extensiones permitidas
        if (allowedExtensions.indexOf(fileExtension) === -1)
          return false; // No es una imagen válida
        else return true; // Es una imagen válida
      }
      return false;
    },
    slotMouseout() {
      this.timeout = setTimeout(() => {
        this.edit = false;
      }, 3000);
    },
    updatedImage() {
      const formData = new FormData();
      formData.append("image", this.tem_image);
      formData.append("name", this.tem_image.name);
      this.patchImage(formData);
      this.$bvModal.hide(`modal-edit-img-${this.image_id}`);
    },
    patchImage(item) {
      let payload = {
        image_id: this.image_id,
        item: item,
      };
      this.$store.dispatch(names.PATCH_IMAGE, payload).then(() => {
        this.tem_image = null;
      });
    },
    alignTop() {
      this.image_form.vertical_align = 1;
      this.$emit("align", "top");
      this.patchImage({ vertical_align: 1 });
    },
    alignBottom() {
      this.image_form.vertical_align = 2;
      this.$emit("align", "bottom");
      this.patchImage({ vertical_align: 2 });
    },
    alignLeft() {
      this.image_form.horizontal_align = 1;
      this.$emit("align", "flex-start");
      this.patchImage({ horizontal_align: 1 });
    },
    alignCenter() {
      this.image_form.horizontal_align = 2;
      this.$emit("align", "center");
      this.patchImage({ horizontal_align: 2 });
    },
    alignRight() {
      this.image_form.horizontal_align = 3;
      this.$emit("align", "flex-end");
      this.patchImage({ horizontal_align: 3 });
    },
    zoomImg() {
      this.$emit("zoomIn", this.image);
    },
    // showOptions() {
    //   this.mouse_over = true;
    // },
    // hideOptions() {
    //   setTimeout(() => {
    //     this.mouse_over = false;
    //   }, 200);
    // },
  },
  watch: {
    image: function () {
      this.image_form = this.image;
    },
  },
  created() {
    if (this.image) {
      this.image_form = this.image;
    } else {
      this.$store
        .dispatch(names.FETCH_IMAGE, this.image_id)
        .then((response) => {
          this.image_form = response;
        });
    }
  },
};
</script>

<style scoped>
.image-card {
  width: 100%;
  height: auto;
  margin: 0.1em 0.3em;
  position: relative;
}
.portrait-img {
  object-fit: contain; /* Asegura que la imagen se ajuste sin distorsionar la relación de aspecto */
  max-width: 100%;
  max-height: 100%;
}
.button-div {
  position: absolute;
  right: -10px;
  top: -8px;
  z-index: 1;
}
.img-display-options {
  background: #fff;
  padding: 0.1em 0.2em;
  border: 1px solid rgb(208, 208, 208);
}
.img-display-options:hover {
  background: rgb(241, 241, 241);
}
.custom-pencil-icon {
  background: #fff;
  border: 1px solid;
  border-radius: 30px;
}
@media (max-width: 950px) {
  .portrait-img {
    width: 150px !important;
    height: 150px !important;
  }
}
@media (max-width: 770px) {
  .portrait-img {
    width: 120px !important;
    height: 120px !important;
  }
}

@media (max-width: 530px) {
  .portrait-button-adjust {
    transform: translateX(-200%);
  }
}
</style>